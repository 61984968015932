// 配置编译环境和线上环境之间的切换

const env = process.env;
var baseUrl = '';
if (env.NODE_ENV == "development") {
  // baseUrl = "https://api.zkzktech.cn";
  baseUrl = "https://api.zkzktech.cn";
} else {
  baseUrl = "https://api.zkzktech.cn";
}

export { baseUrl,env };
